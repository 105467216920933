/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { environment } from '../enviroments/enviroment';
import { GetTokenResponse } from '../models/response/get-token.response';
import { AgilcredBaseService } from './agilcred-base.service';
import { lastValueFrom, Observable } from 'rxjs';
import { LoginResponse } from '../models/response/login.response';

const { clientUser, clientPass } = environment;

@Injectable({
    providedIn: 'root',
})
export class AuthService extends AgilcredBaseService {
    getToken(): Promise<GetTokenResponse> {
        return lastValueFrom(this.post<GetTokenResponse>(`get-token?usuario=${clientUser}&contrasena=${clientPass}`, null));
    }

    loginWithCode(code: number, uuid: string): Observable<LoginResponse> {
        return this.post<LoginResponse>('code-signin', { code: code.toString(), uuid });
    }
}
