export enum AppModules {
    CREDIT_REQUEST = 'credit-request',
    VERIFICATION = 'verification',
}


export enum CreditRequestRoutes {
    CREDIT_INFO = 'info',
    ONBOARDING_START = 'start',
    ONBOARDING_START_LOGIN = 'start-login',
    ONBOARDING = 'on-boarding',
    SMS = 'sms',
    BANK_OR_NOT = 'bank-or-not',
    ERROR = 'error',
    CUSTOM_ERROR = 'error',
    LOGIN = 'login',
    HAVE_BANK = 'have-bank',
    DONT_HAVE_BANK = 'dont-have-bank',
    DONT_HAVE_BANK_INFO = 'dont-have-bank-info',
    REQUEST_CREDIT_CHECK = 'request-credit-check',
    REQUEST_CREDIT_RESOLUTION = 'request-credit-resolution',
    UPDATE_PHONE = 'update-phone',
    BANKING_DATA = 'banking-data',
    DIGITAL_ONBOARDING = 'digital-onboarding',
    ADD_PAYMENT_CARD = 'add-payment-card',
    ADD_PAYMENT_CARD_DONE = 'add-payment-card-done',
    ADD_PAYMENT_CARD_HOME = 'add-payment-card-home',
    CreditRequestRoutes = "CreditRequestRoutes"
}

export enum VerificationRoutes {
    PROCESS_CREDIT_REQUEST = 'process',
    FRAUD_RISK = 'risk',
}
