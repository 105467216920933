export interface PrestamosCalculosResponse {
    status: string;
    data: PrestamosCalculos;
}

export interface AceptaCobroAutomaticoResponse {
    status: string;
    accepted: boolean;
}

export interface PrestamosCalculos {
    importes: number[];
    dias: number[];
    calculos: {
        [importe: number]: {
            [dia: number]: {
                interes: number;
                total: number;
            };
        };
    };
    fechas_devolucion: {
        [dia: number]: string;
    };
}

export class User {
    usuario: string;
    contrasena: string;
    nombre: string;
    apellido: string;
    segundo_apellido: string;
    email: string;
    celular: string;
    direccion_calle: string;
    direccion_numero: string;
    direccion_piso?: string;
    direccion_departamento: string;
    codigo_postal: string;
    provincia_id: number;
    ciudad: string;
    fecha_nacimiento: string; // Formato: 'YYYY-MM-DD'
    estado_civil_id: number;
    acepta_recibir_informacion: boolean;
    acepta_compartir_datos_personales: boolean;

    constructor(user: User) {
        this.usuario = user.usuario;
        this.contrasena = user.contrasena;
        this.nombre = user.nombre;
        this.apellido = user.apellido;
        this.segundo_apellido = user.segundo_apellido;
        this.email = user.email;
        this.celular = user.celular;
        this.direccion_calle = user.direccion_calle;
        this.direccion_numero = user.direccion_numero;
        this.direccion_piso = user.direccion_piso;
        this.direccion_departamento = user.direccion_departamento;
        this.codigo_postal = user.codigo_postal;
        this.provincia_id = user.provincia_id;
        this.ciudad = user.ciudad;
        this.fecha_nacimiento = user.fecha_nacimiento;
        this.estado_civil_id = user.estado_civil_id;
        this.acepta_recibir_informacion = user.acepta_recibir_informacion;
        this.acepta_compartir_datos_personales = user.acepta_compartir_datos_personales;
    }

    public static Empty(): User {
        return new User({
            usuario: '',
            contrasena: '',
            nombre: '',
            apellido: '',
            segundo_apellido: '',
            email: '',
            celular: '',
            direccion_calle: '',
            direccion_numero: '',
            direccion_piso: '',
            direccion_departamento: '',
            codigo_postal: '',
            provincia_id: 0,
            ciudad: '',
            fecha_nacimiento: '',
            estado_civil_id: 0,
            acepta_compartir_datos_personales: false,
            acepta_recibir_informacion: false,
        });
    }
}
export interface CreditData {
    importe: number;
    importe_total: number;
    interes: number;
    dias: number;
    fecha_devolucion: string;
    acepta_terminos_condiciones: boolean;
}
export interface DatosPrestamo {
    status: string;
    data: {
        motivos_prestamos: MotivoPrestamo[];
    };
}

export interface MotivoPrestamo {
    id: number;
    nombre: string;
}
export interface DatosEmpleo {
    status: string;
    data: {
        empleos: Empleo[];
    };
}

export interface Empleo {
    id: number;
    nombre: string;
}

export interface DatosProvincias {
    status: string;
    data: {
        provincias: Provincia[];
    };
}
export interface Provincia {
    id: number;
    nombre: string;
}

export interface EstadoCivil {
    id: number;
    nombre: string;
}
export interface DatosEstadoCivil {
    status: string;
    data: {
        estados_civiles: EstadoCivil[];
    };
}

export interface DatosBancarios {
    empleo_id: number;
    motivo_prestamo_id: number;
    ingresos_mensuales: number;
    fecha_acreditacion_haberes: string;
}
