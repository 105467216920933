import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SimulatorModule } from './modules/simulator/simulator.module';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IStorageToken } from './interfaces/storage-service.interface';
import { ICryptoToken } from './interfaces/encryption-service.interface';
import { IParserToken } from './interfaces/parse-service.interface';
import { CryptoService } from './services/crypto.service';
import { ParseService } from './services/object-parse.service';
import { StorageService } from './services/storage.service';
import { AuthInterceptor } from './interceptos/auth-interceptor';
import { NgxSpinnerService } from 'ngx-spinner';
import { SpinnerService } from './services/spinner.service';
import { ErrorInterceptor } from './interceptos/error.interceptor';
import { APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';

@NgModule({
    imports: [SimulatorModule, AppRoutingModule, CommonModule, HttpClientModule, BrowserAnimationsModule, BackButtonDisableModule.forRoot({ preserveScroll: true })],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (): () => void => () => null,
            deps: [Sentry.TraceService],
            multi: true,
        },
        { provide: IStorageToken, useClass: StorageService },
        { provide: IParserToken, useClass: ParseService },
        { provide: ICryptoToken, useClass: CryptoService },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: NgxSpinnerService, useExisting: SpinnerService },
    ],
    declarations: [
    ],
})
export class AppModule { }
