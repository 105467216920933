/*import { IStorage } from '../interfaces/storage-service.interface';

export class StorageService implements IStorage {
  storeItem(key: string, item: string): Promise<void> {
    return new Promise((resolve) => resolve(localStorage.setItem(key, item)));
  }

  retrieveItem(key: string): Promise<string | null> {
    return new Promise((resolve) => resolve(localStorage.getItem(key)));
  }
}
*/

import { Injectable } from '@angular/core';
import { IStorage } from '../interfaces/storage-service.interface';
import { LoggerService } from 'src/app/services/logger.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService implements IStorage {
  private storageType: 'localStorage' | 'sessionStorage' | 'cookie';

  constructor(private logger: LoggerService) {
    if (this.isLocalStorageAvailable()) {
      this.storageType = 'localStorage';
    } else if (this.isSessionStorageAvailable()) {
      this.storageType = 'sessionStorage';
    } else {
      this.storageType = 'cookie';
    }

    this.logger.info('Tipo de guardado: ' + this.storageType);
  }

  private isLocalStorageAvailable(): boolean {
    try {
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      return true;
    } catch (e) {
      return false;
    }
  }

  private isSessionStorageAvailable(): boolean {
    try {
      sessionStorage.setItem('test', 'test');
      sessionStorage.removeItem('test');
      return true;
    } catch (e) {
      return false;
    }
  }

  storeItem(key: string, item: string): Promise<void> {
    return new Promise((resolve) => {
      if (this.storageType === 'localStorage') {
        localStorage.setItem(key, item);
      } else if (this.storageType === 'sessionStorage') {
        sessionStorage.setItem(key, item);
      } else {
        document.cookie = `${key}=${item};path=/`;
      }
      resolve();
    });
  }

  retrieveItem(key: string): Promise<string | null> {
    return new Promise((resolve) => {
      let value: string | null = null;
      if (this.storageType === 'localStorage') {
        value = localStorage.getItem(key);
      } else if (this.storageType === 'sessionStorage') {
        value = sessionStorage.getItem(key);
      } else {
        const name = key + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            value = c.substring(name.length, c.length);
          }
        }
      }
      resolve(value);
    });
  }
}
