<div class="app-container" #appContainer>
  <div class="card">
    <div class="card__head">
      <div class="card__title">Calcula tu crédito online</div>
      <app-slider-agil-cred
        [title]="'¿Cuánto dinero necesitas?'"
        [value]="amount"
        [min]="minAmount"
        [max]="maxAmount"
        [step]="stepAmount"
        (values)="getDataSlider($event, undefined)"
        [class]=""
      >
      </app-slider-agil-cred>
      <div style="padding-top: 17px">
        <app-slider-agil-cred
          title="¿En cuántos días quieres devolverlo?"
          [value]="dias"
          [min]="minDays"
          [max]="maxDays"
          [step]="stepDays"
          [isCash]="false"
          (values)="getDataSlider(undefined, $event)"
          sub_title="días"
          class="date__slide"
        >
        </app-slider-agil-cred>
      </div>
    </div>

    <app-info-credit-simulator class="mb-3" />

    <app-button size="big" [disabled]="isDisabled" [loading]="isDisabled" (clicked)="getCredit()">
      Solicita tu préstamo
    </app-button>
  </div>
</div>
