/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, mergeMap } from 'rxjs';
import { SecureStorageService } from '../services/secure-storage.service';
import { environment } from '../enviroments/enviroment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private publicEndpoints = [
        'prestamos-calculos',
        'formularios/estados-civiles',
        'formularios/provincias',
        'formularios/empleos',
        'formularios/motivos-prestamos',
        'existe-cliente',
        'login',
        'registro',
        'verification-code/create',
        'code-signin',
    ].map((item) => `${environment.apiUrl}/${item}`);

    constructor(private secureStorage: SecureStorageService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const key = this.publicEndpoints.includes(req.url) ? environment.getTokenKey : environment.loggedTokenKey;
        return from(this.secureStorage.retrieveDecrypted(key)).pipe(
            mergeMap((token) => {
                if (token) {
                    const authReq = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
                    return next.handle(authReq);
                }

                return next.handle(req);
            }),
        );
    }
}
