export const environment = {
    giveawayLineaId: 4,
    defaultLineaId: 1,
    name: 'local',
    production: false,
    apiUrl: 'https://agilcredonline.backend.qa.quo.ar/api',
    clientUser: 'agilcred',
    clientPass: 'systok456321',
    getTokenKey: 'getToken',
    loggedTokenKey: 'getLoggedToken',
    loggedData: 'loggedData',
    dateFormat: 'yyyy-MM-dd',
    aes: {
        key: 'cgmL;qd._NHjb@26&k;CyVnS5fTwzB7@',
        iv: ':EQn@,NsH6&E;Jp/',
    },
} as const;
