import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User, CreditData, DatosBancarios } from '../models/credit-simulator-interface';
import { BankAccount } from '../models/response/account.response';
import { WidgetData } from '../models/widget-data.interface';
import { SendVerificationCodeResponse } from '../models/response/send-verification-code.response';
import { PaymentCard } from '../models/payment-card.model';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    private user$ = new BehaviorSubject<User>(User.Empty());
    private creditData$ = new BehaviorSubject<CreditData | null>(null);
    private codeData$ = new BehaviorSubject<SendVerificationCodeResponse | null>(null);
    private bankingData$ = new BehaviorSubject<DatosBancarios | null>(null);
    private customerPhone$ = new BehaviorSubject<string | null>(null);
    private selectedBankAccount$ = new BehaviorSubject<BankAccount | null>(null);
    private loggedByUrl$ = new BehaviorSubject(false);
    private isCardGoingToBeProvided$ = new BehaviorSubject(false);
    private selectedCard$ = new BehaviorSubject<PaymentCard | undefined>(undefined);

    private widgetData$ = new BehaviorSubject<WidgetData | null>(null);

    //Giveaway data
    private link$ = new BehaviorSubject<string | null>(null);
    private isGiveAwayActive$ = new BehaviorSubject(false);

    public setSelectedCard(data: PaymentCard | undefined): void {
        this.selectedCard$.next(data);
    }

    public getSelectedCard$(): Observable<PaymentCard | undefined> {
        return this.selectedCard$.asObservable();
    }

    public setCodeData(data: SendVerificationCodeResponse): void {
        this.codeData$.next(data);
    }

    public getCodeData$(): Observable<SendVerificationCodeResponse | null> {
        return this.codeData$.asObservable();
    }

    public setWidgetData(widgetData: WidgetData): void {
        this.widgetData$.next(widgetData);
    }

    public setLink(link: string, active = true): void {
        this.link$.next(link);
        this.isGiveAwayActive$.next(active);
    }

    public getIsGiveawayActive$(): Observable<boolean> {
        return this.isGiveAwayActive$.asObservable();
    }

    public getGiveawayLink$(): Observable<string | null> {
        return this.link$.asObservable();
    }

    public getWidgetData(): Observable<WidgetData | null> {
        return this.widgetData$.asObservable();
    }

    public setBankAccount(account: BankAccount): void {
        this.selectedBankAccount$.next(account);
    }

    public getBankAccountObservable(): Observable<BankAccount | null> {
        return this.selectedBankAccount$;
    }

    public getCreditDataObservable(): Observable<CreditData | null> {
        return this.creditData$.asObservable();
    }

    public getBankingDataObservable(): Observable<DatosBancarios | null> {
        return this.bankingData$.asObservable();
    }

    public getUserObservable(): Observable<User> {
        return this.user$.asObservable();
    }

    public getCustomerPhoneObservable(): Observable<string | null> {
        return this.customerPhone$;
    }

    public async updateUserData(user: Partial<User>): Promise<void> {
        this.user$.next({ ...this.user$.value, ...user });
    }

    public setBankingData(data: DatosBancarios): void {
        this.bankingData$.next(data);
    }

    public setCreditData(data: CreditData): void {
        this.creditData$.next({ ...data });
    }

    public updateCreditData(data: Partial<CreditData>): void {
        if (this.creditData$.value) this.creditData$.next({ ...this.creditData$.value, ...data });
    }

    public setPhone(phone: string): void {
        this.customerPhone$.next(phone);
    }

    public setLoggedByUrl(logged: boolean): void {
        this.loggedByUrl$.next(logged);
    }

    public getLoggedByUrlObservable(): Observable<boolean> {
        return this.loggedByUrl$.asObservable();
    }


    public setIsCardGoingToBeProvided(provided: boolean): void {
        this.isCardGoingToBeProvided$.next(provided);
    }

    public getIsCardGoingToBeProvidedAsObservable(): Observable<boolean> {
        return this.isCardGoingToBeProvided$.asObservable();
    }

}
